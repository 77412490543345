import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">
      
      <Container>
        <div className="text-center">
          <h2 className="heading-h2"><span className="bluecolor">Frequently</span> Asked Questions</h2>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                Which Tron token standard should I utilize for token development?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">There are multiple Tron blockchain standards available to develop versatile and secure Tron-based crypto tokens for your needs. Some of the prevalent technical standards in the Tron blockchain include TRC10, TRC20, and TRC721. You can choose any of these standards for token creation as per your business concepts.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                How much does it cost to develop a Tron token?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">It depends on the number of features, unique functionalities you integrate, terms and conditions that you want to incorporate into the Tron token smart contracts, and the level of customization you require. These are the vital factors that determine the Tron token development cost.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                How long does it take to create a Tron Token?
                </Accordion.Toggle>

              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">A typical Tron token can be built within a week or at the most two weeks. As is the case with expenses, how much time it ultimately takes depends on the specs of the Tron token you want us to develop for you.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                What are the various ways in which I can use the Tron tokens I have created?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">Once we have developed your Tron-based crypto token, you will be able to use it for financial transactions, fundraising, crypto payments, trading, and more. You can use it to legitimize real-world valuable assets. It can be used for micro-payments as well as huge payments. 
                </div>
              </Accordion.Collapse>
            </div>

        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection