import React, { Component } from 'react'

import ButtonComponent from '../ButtonComponent/ButtonComponent'


class CoreFeatures extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h2 className="heading-h2"><span className="heading-h4"><span className="bluecolor">Core Features</span> of our </span>
            Tron Token Development</h2>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  Free Transactions
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Energy and Bandwidth
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  Admin Dashboard 
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  Audit Smart Contract
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  View User Details
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Admin Wallet 
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  KYC Submits 
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  Affiliate Program
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  TRON Standards
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  Token Listing
                </li>
                
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Free Transactions
                        </h3>
                        <p className="pharagraph">Tron’s system could seem quite a bit more complicated than the Ethereum and Binance Smart Chain. But it’s also faster, cheaper, it ensures a number of free transactions for all users.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/tron/free-transactions.png" alt="Free Transactions" />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Energy and Bandwidth </h3>
                        <p className="pharagraph">Bandwidth is required to complete TRX transactions. This is the value that will be reduced with each transaction. Energy is a special resource that is used to process smart contracts on the TRON network.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/tron/energy-and-bandwidth.png" alt="Energy and Bandwidth" />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4"> Admin Dashboard</h3>
                        <p className="pharagraph">Admin can view the count of total users, KYC verified users, pending KYC, unread support tickets and much more.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/tron/Admin Dashboard.png" alt="Admin Dashboard" />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Audit Smart Contract
                        </h3>
                        <p className="pharagraph">This process is conducted to discover errors, issues and security vulnerabilities in the contracts.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/tron/Audit Smart Contract.svg" alt="Audit Smart Contract" />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">View User Details</h3>
                        <p className="pharagraph">Admin can view user details such as name, email, email verification status, KYC status, etc., 
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/tron/View User Details.svg" alt="View User Details" />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Admin Wallet</h3>
                        <p className="pharagraph">Admin can receive crypto from users during user deposit and also they can send crypto to them during withdrawal.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/tron/Admin Wallet.svg" alt="Admin Wallet" />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">KYC Submits</h3>
                        <p className="pharagraph">Our software will be compliant with KYC/AML for user identification. The regulations will be applied to all major countries. The users who are registering on your system will be verified and authenticated through their given KYC information.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/tron/KYC Submits.png" alt="KYC Submits" />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Affiliate Program</h3>
                        <p className="pharagraph">Users can invite their friends or other crypto users so that they can earn rewards or bonuses.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/tron/Affiliate Program.svg" alt="Affiliate Program" />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">TRON Standards</h3>
                        <p className="pharagraph">TRC10 is a technical token standard supported by the TRON blockchain natively. TRC20 is a technical standard used for smart contracts on the TRON blockchain or implementing tokens with the TRON Virtual Machine (TVM).</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/tron/TRON Standards.svg" alt="TRON Standards" />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Token Listing</h3>
                        <p className="pharagraph">Listing your tokens in top listing websites is now hassle-free. We take total care of all your technological requirements. Our experienced cryptocurrency exchange listing experts will ensure that your token is listed on the major exchanges once it is ready.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/tron/Token Listing.png" alt="Token Listing" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures