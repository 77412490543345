import React from 'react'


const DevApproach = () => {


  return (
    <section className="devapproach pt-100 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h2 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Step by Step Process </span>of our </span>
              Tron Token Development
            </h2>
            <p className="pharagraph head">Coinsclone follows some standard procedures to create and deploy Tron Tokens.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12  text-center order1" >
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/tron/project-analysis.svg" alt="project analysis" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h3 >Requirement Gathering</h3>
            <p  className="pharagraph">Initially, we will collect the requirements from our clients and analyze them to create Tron tokens. After the research is done, we will provide the ideal solution for starting a token business on the Tron blockchain, based on the client's requirements.
            </p>
            <br />
            <h3 >Planning </h3>
            <p  className="pharagraph">Our group of blockchain experts will derive a rough plan for Tron token creation based on the client's business concepts.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h3 >Tron Token Designing</h3>
            <p  className="pharagraph">Each design that is implemented in the Tron Token will be moved to the next stage after confirmation from both the client side and the designer team.
            </p>
            <br />
            <h3 >Tron Token Development</h3>
            <p  className="pharagraph">After the designing process is done, we implement the advanced protocols and develop outstanding TRC20 tokens as per the client's needs. 
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-center order1">
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/tron/designing-development.svg" alt="Designing and development" /></div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12  text-center order1" >
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/tron/testing-deployment.svg" alt="Testing and deployment" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h3 >Testing Tron Token</h3>
            <p  className="pharagraph">After the development stage, our testing team will check the quality of the Tron Token by conducting various tests. If any functionality doesn’t work properly, then our team will rectify the bug or issue instantly.
            </p>
            <br />
            <h3 >Deployment</h3>
            <p  className="pharagraph mb-0">Our Tron token development team will deploy the feature-rich tokens on the Tron blockchain as per the client’s request.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach