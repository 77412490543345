import React from 'react'

class TechStack extends React.Component {


  render() {
    return (
      <div className='pt-100'>
      <section className="binancestack technostack dev-tech-stack mb-0">
        <div className="container">
          <div className="row table-content">
            <div className="col-md-12 col-lg-6 text-left">
              <h2 className="heading-h2"><span className="bluecolor">Technologies Used</span> For our Tron Token Development</h2>
              <p className="pharagraph">We at Coinsclone use a set of cutting-edge technologies for creating and deploying bug-free Tron Tokens
              </p>
            </div>
            <div className="col-md-12 col-lg-6">
              <img className="logostck" width="573px" height="268px" src="https://coinsclone.mo.cloudinary.net/images/whitelabel/technologies-stock.svg" alt="Technologies stock" />
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default TechStack