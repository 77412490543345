import React from 'react'




class UsecaseOf extends React.Component {


  render() {

    return (
      <section className="usecase bgremove pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor"> Use Case</span> of our</span>
                Tron Token Development</h3>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/tron/usecase.svg" alt="Use Case of Tron Token Development" />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph" ><b>Financial transactions -</b> Tron tokens can be used to carry out conventional financial transactions because they are compatible with all contemporary exchanges.
              </p>
              <p className="pharagraph" ><b>Intellectual property -</b> Once the Tron-based tokens are created, they can be used to protect the intellectual corporate properties of creators.
              </p>
              <p className="pharagraph" ><b>Micro-payments -</b>  Very small payments are not possible using conventional financial institutions. Whereas, they can be easily carried out using Tron tokens.
              </p>
              <p className="pharagraph" ><b>Convertibility -</b>  Real-world valuable assets can be easily converted using the TRC721 token standard. Besides, Tron tokens can be minted using the TRC20 standard.
              </p>
              <p className="pharagraph" ><b>Logistics services -</b> Many logistics companies are already using Tron tokens to process payments and track the movements of their fleets according to mileage.
              </p>
              <p className="pharagraph mb-0" ><b>Banking -</b>  Monetary transactions are being carried out using Tron tokens instead of conventional currency because currency conversion charges are not required for international payments.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default UsecaseOf