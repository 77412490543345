import React from 'react'

class WhyShould extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (  
      <section className="whyshould pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">Why should</span> you develop Tron Tokens?
              </h2>
              <p className="pharagraph head">Creating feature-rich Tron tokens on the Tron blockchain can help you achieve substantial profits in a short period. Additionally, here are a few significant reasons to create Tron-based tokens.
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/tron/why-should-you-start-tron-token.svg" alt="Why Should Start Tron Token Development" />
            </div>
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph"  >If you want to leverage the power of the Tron virtual machine, then develop your own Tron tokens with top-class features.</li>
                <li className="pharagraph" >High compatibility and scalability.
                </li>
                <li className="pharagraph" >No limitations in data storage.</li>
                <li className="pharagraph" >Your users can make payments securely through a p2p decentralized network.</li>
                <li className="pharagraph" >Tron tokens are highly responsive and come with a unique framework.</li>
                <li className="pharagraph" >Although the technology is similar in functions and capabilities to Ethereum, a very big difference is that Tron tokens work on the proof-of-stake consensus algorithm.</li>
                <li className="pharagraph" >Tron tokens can facilitate faster transactions.</li>
                <li className="pharagraph" >Tron tokens are compatible both with BEP20 and ERC20 tokens. </li>
                <li className="pharagraph" >The Tron tokens are supported by all mainstream crypto wallets.</li>
              </ul>
            </div>
          </div>
          <div className='text-center'>
          <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Hire Our Tron Token Developers </a>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould